var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container mb4" }, [
    _c("div", { staticClass: "main-content" }, [
      _c("div", { staticClass: "cards-grid" }, [
        _c(
          "div",
          {
            staticClass: "card bg-adori-light-gray",
            on: {
              click: function ($event) {
                return _vm.handleMethod("IDEA")
              },
            },
          },
          [_vm._m(0)]
        ),
        _c(
          "div",
          {
            staticClass: "card bg-adori-light-gray",
            on: {
              click: function ($event) {
                return _vm.handleMethod("URL")
              },
            },
          },
          [_vm._m(1)]
        ),
        _c(
          "div",
          {
            staticClass: "card bg-adori-light-gray",
            on: {
              click: function ($event) {
                return _vm.handleMethod("TEXT")
              },
            },
          },
          [_vm._m(2)]
        ),
        _c(
          "div",
          {
            staticClass: "card bg-adori-light-gray",
            on: {
              click: function ($event) {
                return _vm.handleMethod("FILE")
              },
            },
          },
          [_vm._m(3)]
        ),
        _c(
          "div",
          {
            staticClass: "card bg-adori-light-gray",
            on: {
              click: function ($event) {
                return _vm.handleMethod("AUDIO")
              },
            },
          },
          [_vm._m(4)]
        ),
        _c(
          "div",
          {
            staticClass: "card bg-adori-light-gray",
            on: {
              click: function ($event) {
                return _vm.handleMethod("PODCAST")
              },
            },
          },
          [_vm._m(5)]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-content" }, [
      _c("div", { staticClass: "icon-circle blue" }, [
        _c("i", { staticClass: "material-icons icon" }, [
          _vm._v("tips_and_updates"),
        ]),
      ]),
      _c("h2", { staticClass: "card-title" }, [_vm._v("Idea to Video")]),
      _c("p", { staticClass: "card-description" }, [
        _vm._v("Use AI to brainstorm ideas and write script"),
      ]),
      _c("button", { staticClass: "action-button blue" }, [
        _vm._v("Get Started"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-content" }, [
      _c("div", { staticClass: "icon-circle purple" }, [
        _c("i", { staticClass: "material-icons icon" }, [_vm._v("link")]),
      ]),
      _c("h2", { staticClass: "card-title" }, [_vm._v("Blog to Video")]),
      _c("p", { staticClass: "card-description" }, [
        _vm._v("Paste your blog link and our AI will auto detect scenes"),
      ]),
      _c("button", { staticClass: "action-button purple" }, [
        _vm._v("Convert Blog"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-content" }, [
      _c("div", { staticClass: "icon-circle green" }, [
        _c("i", { staticClass: "material-icons icon" }, [
          _vm._v("content_paste"),
        ]),
      ]),
      _c("h2", { staticClass: "card-title" }, [_vm._v("Text to Video")]),
      _c("p", { staticClass: "card-description" }, [
        _vm._v("Paste text from any website i.e blog, article, news etc."),
      ]),
      _c("button", { staticClass: "action-button green" }, [
        _vm._v("Start with Text"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-content" }, [
      _c("div", { staticClass: "icon-circle orange" }, [
        _c("i", { staticClass: "material-icons icon" }, [
          _vm._v("text_snippet"),
        ]),
      ]),
      _c("h2", { staticClass: "card-title" }, [_vm._v("PDF to Video")]),
      _c("p", { staticClass: "card-description" }, [
        _vm._v("Upload pdf having images or plain text file"),
      ]),
      _c("button", { staticClass: "action-button orange" }, [
        _vm._v("Upload File"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-content" }, [
      _c("div", { staticClass: "icon-circle teal" }, [
        _c("i", { staticClass: "material-icons icon" }, [_vm._v("audiotrack")]),
      ]),
      _c("h2", { staticClass: "card-title" }, [_vm._v("Audio to Video")]),
      _c("p", { staticClass: "card-description" }, [
        _vm._v("Convert your audio files into engaging videos"),
      ]),
      _c("button", { staticClass: "action-button teal" }, [
        _vm._v("Convert Audio"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-content" }, [
      _c("div", { staticClass: "icon-circle pink" }, [
        _c("i", { staticClass: "material-icons icon" }, [_vm._v("mic")]),
      ]),
      _c("h2", { staticClass: "card-title" }, [_vm._v("Podcast to Video")]),
      _c("p", { staticClass: "card-description" }, [
        _vm._v("Transform your podcast episodes into dynamic video"),
      ]),
      _c("button", { staticClass: "action-button pink" }, [
        _vm._v("Select Podcast"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }