var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "TheMainPage",
        [
          _c("TheHeader"),
          _c(
            "div",
            { staticClass: "flex flex-wrap" },
            [
              _vm.selectedProduct === "VIDEO"
                ? _c("TheSideNavigation")
                : _vm._e(),
              _c(
                "div",
                { staticClass: "limiter-no-padding scrollbar relative" },
                [
                  _c("div", { staticClass: "mt4" }, [
                    _c(
                      "div",
                      {
                        staticClass: "flex",
                        attrs: { slot: "left" },
                        slot: "left",
                      },
                      [
                        _c("div", { staticClass: "header" }, [
                          _c("div", { staticClass: "header-buttons" }, [
                            _vm.$permissions.isViewPodcastAllowed() &&
                            _vm.selectedProduct === "VIDEO"
                              ? _c(
                                  "button",
                                  {
                                    class: {
                                      "create-button":
                                        _vm.selectedCollectionType ===
                                        "ALL_PODCASTS",
                                      "secondary-button":
                                        _vm.selectedCollectionType !=
                                        "ALL_PODCASTS",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.selectedCollectionType =
                                          "ALL_PODCASTS"
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Podcasts (" +
                                        _vm._s(_vm.rssFeedsCount || 0) +
                                        ")\n                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.$permissions.isViewPodcastAllowed() &&
                            _vm.selectedProduct === "BLOG"
                              ? _c(
                                  "button",
                                  {
                                    class: {
                                      "create-button":
                                        _vm.selectedCollectionType ===
                                        "BLOG_TO_VIDEO",
                                      "secondary-button":
                                        _vm.selectedCollectionType !=
                                        "BLOG_TO_VIDEO",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.selectedCollectionType =
                                          "BLOG_TO_VIDEO"
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Create Video\n                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.$permissions.isViewEpisodeAllowed() &&
                            _vm.selectedProduct === "BLOG"
                              ? _c(
                                  "button",
                                  {
                                    class: {
                                      "create-button":
                                        _vm.selectedCollectionType ===
                                        "BLOG_DRAFT",
                                      "secondary-button":
                                        _vm.selectedCollectionType !=
                                        "BLOG_DRAFT",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.selectedCollectionType =
                                          "BLOG_DRAFT"
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Drafts (" +
                                        _vm._s(_vm.draftCount || 0) +
                                        ")\n                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "button",
                              {
                                class: {
                                  "create-button":
                                    _vm.selectedCollectionType === "ALL_AUDIOS",
                                  "secondary-button":
                                    _vm.selectedCollectionType != "ALL_AUDIOS",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.selectedCollectionType = "ALL_AUDIOS"
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  My Videos (" +
                                    _vm._s(_vm.allAudioTracksCount || 0) +
                                    ")\n                "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _c("div", { attrs: { slot: "right" }, slot: "right" }, [
                      _vm.selectedProduct === "VIDEO"
                        ? _c("div", { staticClass: "w-100 mv3" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex justify-between items-center light-gray",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "flex" },
                                  [
                                    _c("div", { staticClass: "flex relative" }),
                                    _vm.$permissions.isCreatePodcastAllowed()
                                      ? _c("BaseButtonRed", {
                                          staticClass:
                                            "bg-adori-light-gray mr2",
                                          attrs: {
                                            text: "Import Podcast",
                                            onClick: _vm.handleCreateNewPodcast,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.$permissions.isEditEpisodeAllowed()
                                      ? _c("BaseButtonRed", {
                                          staticClass: "bg-adori-light-gray",
                                          attrs: {
                                            text: "Upload Audio/Video",
                                            onClick: _vm.handleUploadAudio,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _vm.selectedCollectionType === "ALL_AUDIOS"
                    ? _c("div", [
                        _vm.allAudioTracksLoading || _vm.rssFeedLoading
                          ? _c(
                              "div",
                              _vm._l(new Array(10), function (_, index) {
                                return _c("div", {
                                  key: index,
                                  staticClass:
                                    "w-100 bg-adori-light-gray bb bw1 b--adori-gray flex justify-center items-center skeleton cus-size",
                                })
                              }),
                              0
                            )
                          : _c(
                              "div",
                              [
                                _vm.allAudioTracksCount === 0
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex flex-column justify-center items-center w-100",
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/no-episodes.svg"),
                                              alt: "",
                                              width: "500",
                                              height: "500",
                                            },
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "white f3" },
                                            [_vm._v("No Uploads Yet!")]
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _c("AllAudioPage", {
                                      attrs: {
                                        editable: "",
                                        showEditButtons: "",
                                        fromYoutube: "",
                                        selectedPodcast: _vm.selectedPodcast,
                                      },
                                    }),
                              ],
                              1
                            ),
                      ])
                    : _vm._e(),
                  _vm.selectedCollectionType === "ALL_PODCASTS"
                    ? _c("div", [
                        _vm.rssFeedLoading
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "flex flex-column justify-center items-center",
                              },
                              [
                                _c("div", {
                                  staticClass:
                                    "bg-adori-medium-gray br3 w-100 skeleton top-size",
                                }),
                                _c("div", {
                                  staticClass:
                                    "bg-adori-medium-gray mt1 br3 w-100 skeleton bottom-size",
                                }),
                              ]
                            )
                          : _c(
                              "div",
                              [
                                _vm.rssFeedsCount === 0
                                  ? _c(
                                      "div",
                                      [
                                        _c("HomeBanner", {
                                          attrs: {
                                            isLargeBanner: "",
                                            bannerHeader: _vm.bannerHeader,
                                            bannerSubHeader:
                                              _vm.bannerSubHeader,
                                            bannerImageUrl: _vm.bannerImageUrl,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c("AllPodcastsPage", {
                                      ref: "allPodcastPage",
                                      attrs: {
                                        showEditButtons: "",
                                        editable: "",
                                        fromYoutube: "",
                                        selectedPodcast: _vm.selectedPodcast,
                                        rssFeedsData: _vm.rssFeedsData.data,
                                      },
                                      on: { selectRss: _vm.selectRss },
                                    }),
                              ],
                              1
                            ),
                      ])
                    : _vm._e(),
                  _vm.selectedCollectionType === "BLOG_TO_VIDEO"
                    ? _c("div", [_c("AIBlog")], 1)
                    : _vm._e(),
                  _vm.selectedCollectionType === "BLOG_DRAFT"
                    ? _c("div", [_c("AIBlogDraft")], 1)
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }